/* eslint-disable react/style-prop-object */
import React from 'react'
import PropTypes from 'prop-types'

import { UILayout, UIFormattedNumber, UIText } from '../../../UI'


const Description = (props) => (
  <UILayout column flex-start width="auto" padding="0 30px 0 0">
    <UIText
      size="16px"
      color="#000000"
      weight="bold"
      align="left"
    >
      {props.title},&nbsp;
      <UIFormattedNumber
        style="currency"
        value={props.price}
        minimumFractionDigits={0}
        currency={props.currency && props.currency.code}
      />
    </UIText>
    <UIText
      size="14px"
      color="#000000"
      align="left"
      padding="10px 0 0 0"
    >{props.description && props.description.description}
    </UIText>
  </UILayout>
)

Description.propTypes = {
  title: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired
}

export default Description
