import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { pathOr } from 'ramda'

import { UIIcon, UILayout, UIText } from '../../UI'
import { THEME } from '../../../themes'
import { getMaritimeStyle, isMaritime } from 'bora/src/utils/maritimeStyleUtils'
import { getStyle, isLiinilaevad } from 'bora/src/utils/liinilaevadThemeUtils'

const getProgressColor = (progress, PROGRESS_COLORS) => {
  if (progress <= 30) return PROGRESS_COLORS.low
  else if (progress >= 70) return PROGRESS_COLORS.high
  return PROGRESS_COLORS.middle
}

// const checkRoute = (route) => route.startsWith('RIN-')

export const capacityWarningNote = '*'
export const noMoreLocalsTicketsNote = '**'

const xsStyles = {
  maxWidth33: { maxWidth: '33px' },
  alignEnd: { alignItems: 'flex-end' },
  size20: { size: '20px' },
  size18: { size: '18px' },
  width36Height4: { width: '36px', minWidth: false, height: '4px' },
}

export default class AvailabilityBar extends PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
    availability: PropTypes.number.isRequired,
    availabilityMax: PropTypes.number.isRequired,
    hideAvailabilityMaxLabel: PropTypes.bool,
    hideIcon: PropTypes.bool,
    isCancelled: PropTypes.bool,
    route: PropTypes.string,
  }

  static defaultProps = {
    type: '',
    availability: 0,
    availabilityMax: 100,
    hideAvailabilityMaxLabel: false,
    hideIcon: false,
    isCancelled: false,
    route: undefined,
  }

  static contextTypes = {
    muiTheme: PropTypes.any,
  }

  render() {
    const {
      type,
      availabilityMax,
      availability,
      isCancelled,
      reserves,
      progressBarForTwoIcons,
      longProgressBarForTwoIcons,
      hideProgressValue,
    } = this.props
    const { muiTheme } = this.context
    const getProgressValue = () => {
      if (availabilityMax === 0) {
        return 0
      }

      return (availability * 100) / availabilityMax
    }

    const PROGRESS_COLORS = {
      low: '#f46e5a',
      middle: '#ffc15c',
      high: pathOr('#50AC59', ['palette', 'green'], this.context.muiTheme),
    }
    const residentScaleColor = !isCancelled && !(reserves || {}).amount ? PROGRESS_COLORS.low : false

    const getSize = (size) => (isLiinilaevad ? size : '28px')

    const font = getMaritimeStyle(
      'ADPortsGroupBold, sans-serif',
      (muiTheme.ids.departure && muiTheme.ids.departure.customFont) || isLiinilaevad
        ? muiTheme.secondFontFamily
        : 'GinesoNormDem,Fjalla One'
    )

    return (
      <UILayout column j-flex-space-between width="auto" data-testid="availability-bar">
        <UIIcon
          display-if={!this.props.hideIcon}
          minWidth="33"
          xs={xsStyles.maxWidth33}
          width="42px"
          height="20px"
          type={`icon${type}`}
          opacity={getProgressValue() <= 0 ? 0.5 : 1}
        />
        <UILayout
          data-testid={`${type}-availability`}
          row
          flex-start
          style={{
            justifyContent: getStyle(isCancelled && 'center'),
            ...xsStyles.alignEnd,
            ...muiTheme.ids.departure,
            ...(isCancelled && type !== 'Passenger' && { justifyContent: 'center' }),
            ...(isMaritime && { justifyContent: 'center' }),
          }}
        >
          <UIText
            display-if={!hideProgressValue}
            size={getMaritimeStyle('22px', getSize('24px', '28px'))}
            weight="bold"
            lineHeight="0.8"
            align={isCancelled ? 'center' : 'left'}
            font={font}
            color={!isCancelled ? getProgressColor(getProgressValue(), PROGRESS_COLORS) : '#bfc3c7'}
            xs={xsStyles.size20}
          >
            {isCancelled
              ? '-'
              : availability +
                (!getProgressValue() && muiTheme.name !== THEME.SAAREMAA && muiTheme.name !== THEME.LIINILAEVAD
                  ? capacityWarningNote
                  : '')}
          </UIText>
          <UIText
            display-if={reserves && reserves.show && !isCancelled && !isMaritime}
            size="18px"
            weight="normal"
            lineHeight="0.8"
            align="left"
            font="GinesoNormDem,Fjalla One"
            color={!residentScaleColor ? getProgressColor(getProgressValue(), PROGRESS_COLORS) : residentScaleColor}
            xs={xsStyles.size18}
            margin="0 0 0 5px"
            padding="0 0 5px 0"
          >
            {`(${reserves.amount}${!reserves.amount ? noMoreLocalsTicketsNote : ''})`}
          </UIText>
          <UIText
            display-if={!this.props.hideAvailabilityMaxLabel}
            size="18px"
            lineHeight="1"
            align="left"
            color="#9b9b9b"
            margin="0 0 0 8px;"
          >
            {availabilityMax}
          </UIText>
        </UILayout>
        <UILayout
          display-if={!isCancelled}
          minWidth="42px"
          position="relative"
          width={longProgressBarForTwoIcons ? '210px' : progressBarForTwoIcons ? '110px' : '42px'}
          bgColor={isCancelled ? 'transparent' : '#e9e9e9'}
          height="6px"
          margin="5px 0 0 0"
          xs={{
            ...xsStyles.width36Height4,
            width: progressBarForTwoIcons || longProgressBarForTwoIcons ? '75px' : '36px',
          }}
        >
          <UILayout
            position="absolute"
            width={getProgressValue() ? `${getProgressValue()}%` : '5px'}
            bgColor={!isCancelled ? getProgressColor(getProgressValue(), PROGRESS_COLORS) : 'transparent'}
            height="6px"
          >
            {' '}
          </UILayout>
        </UILayout>
      </UILayout>
    )
  }
}
