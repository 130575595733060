import React from 'react'
import PropTypes from 'prop-types'
import { UIButton, UIIcon, UILayout, UIText } from '../UI'


const getPaymentButtonParameters = (props, context) => {
  const { muiTheme } = context
  const { type = 'apple', ...params } = props

  const found = type.search(/apple/i)
  const isApple = !!(type === '' || found >= 0)
  const id = isApple ? 'payViaApple' : 'payViaCards'

  let customThemeProps = {}
  if (muiTheme) customThemeProps = muiTheme.ids[id] || {}

  const defaultProps = {
    height: '88px',
    fullWidth: true,
    disabled: false,
    iconMargin: '0 12px',
    textPadding: '0 27px',
    textSize: '18px',
    textAlign: 'left'
  }

  const defaultApple = {
    text: 'payWith',
    background: '#000000',
    color: 'white'
  }
  const defaultCards = {
    text: 'payWithCards',
    background: 'white',
    color: '#000000'
  }

  const curType = isApple ? defaultApple : defaultCards

  const obj = {}
  const arr = type.split(/[,-:\s]+/)
  obj.iconArr = arr.map((item) => {
    let icon

    switch (item) {
      case 'visa':
        icon = 'visa'
        break
      case 'mastercard':
        icon = 'mastercard'
        break
      default:
        icon = 'applePay'
        break
    }

    return icon
  })
  obj.isApple = isApple

  const internals = {
    ...defaultProps,
    ...curType,
    ...obj,
    ...customThemeProps,
    ...params
  }


  return internals
}

const PaymentButton = (props, context) => {
  const internals = getPaymentButtonParameters(props, context)
  const {
        id,
        text,
        color,
        textPadding,
        textSize,
        textAlign,
        iconArr,
        iconMargin,
        click = () => {},
        isApple,
        ...params
    } = internals

  const createIcons = (arr) => arr.map((item) => <UIIcon type={item} margin={iconMargin} />)

  return (
    <UIButton {...params} click={() => click(isApple ? 'apple' : 'cards')}>
      <UILayout j-flex-center center lineHeight="30px" height="100%" >
        {params.children ? params.children : createIcons(iconArr)}
        <UIText
          xs={0}
          align={textAlign}
          size={textSize}
          position="absolute"
          width="100%"
          padding={textPadding}
          color={color}
          translate={props.label}
        />
      </UILayout>
    </UIButton>
  )
}

PaymentButton.contextTypes = {
  muiTheme: PropTypes.object
}

PaymentButton.propTypes = {
  label: PropTypes.string.isRequired
}


export default PaymentButton
