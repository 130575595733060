/* eslint-disable camelcase */
import search from '!raw-loader!./search.svg'
import arrow from '!raw-loader!./arrow.svg'
import cancel from '!raw-loader!./cancel.svg'
import add from '!raw-loader!./add.svg'
import remove from '!raw-loader!./remove.svg'
import selectTicketArrow from '!raw-loader!./selectTicketArrow.svg'
import applePay from '!raw-loader!./apple-pay.svg'
import mastercard from '!raw-loader!./mastercard.svg'
import visa from '!raw-loader!./visa.svg'
import iconChevronDown from '!raw-loader!./icon-chevron-down.svg'
import iconChevronDown_saaremaa from '!raw-loader!./icon-chevron-down_saaremaa.svg'
import iconChevronDownLang from '!raw-loader!./icon-chevron-down-lang.svg'
import iconAuto from '!raw-loader!./icon-auto.svg'
import iconAutoTicket from '!raw-loader!./icon-auto-ticket.svg'
import iconPassenger from '!raw-loader!./icon-passenger.svg'
import iconPassengerTicket from '!raw-loader!./icon-passenger-ticket.svg'
import iconTruck from '!raw-loader!./icon-truck.svg'
import iconTruckTicket from '!raw-loader!./icon-truck-ticket.svg'
import plusInactive from '!raw-loader!./plus-inactive.svg'
import foliage from '!raw-loader!./foliage.svg'
import video from '!raw-loader!./video.svg'
import iconClose from '!raw-loader!./icon-close.svg'
import iconGoogle from '!raw-loader!./icon-google.svg'
import iconSmartId from '!raw-loader!./icon-smartID.svg'
import iconSmartIdColored from '!raw-loader!./icon-smartID-colored.svg'
import iconSmartIdLogo from '!raw-loader!./icon-smartID-logo.svg'
import iconFB from '!raw-loader!./icon-fb.svg'
import iconEye from '!raw-loader!./icon-eye.svg'
import iconSignout from '!raw-loader!./icon-signout.svg'
import iconLoyalty from '!raw-loader!./icon-loyalty.svg'
import iconDirections from '!raw-loader!./icon-directions.svg'
import defaultUser from '!raw-loader!./default-user.svg'
import smartId from '!raw-loader!./smartID.svg'
import iconApplePay from '!raw-loader!./icon-apple-pay.svg'

import iconShip from '!raw-loader!./icon-ship.svg'
import iconBidirectional from '!raw-loader!./icon-bidirectional.svg'
import iconCar from '!raw-loader!./icon-car.svg'
import iconCarLL from '!raw-loader!./icon-car-ll.svg'
import iconCabin from '!raw-loader!./icon-cabin.svg'
import iconDishes from '!raw-loader!./icon-dishes.svg'
import iconCarsHeight from '!raw-loader!./icon-cars-height.svg'
import iconCarsWeight from '!raw-loader!./icon-cars-weight.svg'
import iconCarsLength from '!raw-loader!./icon-cars-length.svg'

import iconWarningTriangle from '!raw-loader!./icon-warning-triangle.svg'
import iconSnowflake from '!raw-loader!./icon-snowflake.svg'
import iconHovercraft from '!raw-loader!./icon-hovercraft.svg'

import iconPhone from '!raw-loader!./icon-phone.svg'
import iconPerson from '!raw-loader!./icon-person.svg'
import iconEmail from '!raw-loader!./icon-email.svg'

import iconChevron from '!raw-loader!./icon-chevron-right.svg'
import iconPricelist_kihnu from '!raw-loader!./icon-pricelist.svg'
import iconPricelist_liinilaevad from '!raw-loader!./icon-pricelist.svg'
import iconPricelist_saaremaa from '!raw-loader!./icon-pricelist.svg'
import iconPricelist_tallink from '!raw-loader!./list_tallink.svg'
import iconHelp_kihnu from '!raw-loader!./icon-help.svg'
import iconHelp_saaremaa from '!raw-loader!./icon-help.svg'
import iconHelp_demo from '!raw-loader!./icon-help.svg'
import iconHelp_tallink from '!raw-loader!./support_tallink.svg'
import iconPencil from '!raw-loader!./icon-pencil.svg'
import iconUser from '!raw-loader!./icon-user.svg'
import iconCompany from '!raw-loader!./icon-company.svg'
import iconState from '!raw-loader!./icon-state.svg'
import iconKey from '!raw-loader!./icon-key.svg'
import iconPassword from '!raw-loader!./icon-password.svg'
import iconWheelchairAccess from '!raw-loader!./wheelchair-access.svg'
import iconErrorFilled from '!raw-loader!./error-filled.svg'
import iconNoHeavyTrucks from '!raw-loader!./icon-no-heavy-trucks.svg'

import iconPhoneLL from '!raw-loader!./icon-phone-ll.svg'
import iconMailLL from '!raw-loader!./icon-mail-ll.svg'
import iconWarningLL from '!raw-loader!./warning-ll.svg'
import iconWarningWhiteLL from '!raw-loader!./warning-white-ll.svg'
import iconExpandLL from '!raw-loader!./news-expand-ll.svg'
import iconCollapseLL from '!raw-loader!./news-collapse-ll.svg'

// SELECT DATETIME
import iconSwapDirections from '!raw-loader!./icon-swap-directions.svg'
import iconExchangeArrows from '!raw-loader!./icon-exchange-arrows.svg'
import iconReturnRoute from '!raw-loader!./icon-return-route.svg'
import iconGoBack from '!raw-loader!./icon-go-back.svg'
import iconGoBackBold from '!raw-loader!./icon-go-back-bold.svg'
import iconTime from '!raw-loader!./icon-time.svg'
import iconTimerRunning from '!raw-loader!./icon-timer-running.svg'

// LANGUAGES

import russian from '!raw-loader!./icon-russia.svg'
import english from '!raw-loader!./icon-uk.svg'
import estonian from '!raw-loader!./icon-estonia.svg'
import greek from '!raw-loader!./icon-greek.svg'
import philippines from '!raw-loader!./icon-philippines.svg'
import spanish from '!raw-loader!./icon-es.svg'
import polish from '!raw-loader!./locales/pl.svg'
import german from '!raw-loader!./locales/de.svg'
import swedish from '!raw-loader!./locales/sv.svg'

// TICKET
import iconTransfer from '!raw-loader!./icon-transfer.svg'
import iconWifi from '!raw-loader!./icon-wifi.svg'
import iconFood from '!raw-loader!./icon-food.svg'
import iconRefund from '!raw-loader!./icon-refund.svg'
import iconPrinter from '!raw-loader!./icon-printer.svg'
import iconDownload from '!raw-loader!./icon-download.svg'
import iconArrow from '!raw-loader!./icon-arrow.svg'
import iconShape from '!raw-loader!./icon-shape.svg'
import iconCombinedShape from '!raw-loader!./combined-shape.svg'

// CARS CATEGORIES
import iconBike from '!raw-loader!./carsCategories/icon-bike.svg'
import iconLightWeight from '!raw-loader!./carsCategories/icon-lightweight.svg'
import iconMicrobus from '!raw-loader!./carsCategories/icon-microbus.svg'
import iconMotorcycle from '!raw-loader!./carsCategories/icon-motorcycle.svg'
import iconTrailer from '!raw-loader!./carsCategories/icon-trailer.svg'
import iconBigTruckTrailer from '!raw-loader!./carsCategories/icon-big-truck-trailer.svg'
import iconHeavyWeightTrailer from '!raw-loader!./carsCategories/icon-heavyweight-trailer.svg'

import iconPiontOk_kihnu from '!raw-loader!./icon-point-ok.svg'
import iconPiontOk_liinilaevad from '!raw-loader!./icon-point-ok.svg'
import iconPiontOk_saaremaa from '!raw-loader!./icon-point-ok.svg'
import iconPiontOk_tallink from '!raw-loader!./check_tallink.svg'
import iconPiontOkRed from '!raw-loader!./icon-point-ok-red.svg'
import iconPointCheck_kihnu from '!raw-loader!./icon-point-check.svg'
import iconPointCheck_liinilaevad from '!raw-loader!./icon-point-check.svg'
import iconPointCheck_saaremaa from '!raw-loader!./icon-point-check.svg'
import iconPointCheck_tallink from '!raw-loader!./check-active_tallink.svg'
import iconPointEmpty_kihnu from '!raw-loader!./icon-point-empty.svg'
import iconPointEmpty_liinilaevad from '!raw-loader!./icon-point-empty.svg'
import iconPointEmpty_saaremaa from '!raw-loader!./icon-point-empty.svg'
import iconPointEmpty_tallink from '!raw-loader!./check-empty_tallink.svg'
import pathOk from '!raw-loader!./path-ok.svg'
import pathDotted from '!raw-loader!./path-dotted.svg'

import iconEditMui from '!raw-loader!./icon-edit-mui.svg'
import iconEdit from '!raw-loader!./icon-edit.svg'
import iconCheck from '!raw-loader!./icon-check.svg'
import iconImportant from '!raw-loader!./icon-important.svg'
import iconQuestion from '!raw-loader!./icon-question.svg'

import triangle from '!raw-loader!./triangle.svg'
import check from '!raw-loader!./check.svg'
import trailer from '!raw-loader!./trailer.svg'
import iconCarStamp from '!raw-loader!./icon-car-stamp.svg'
import iconGear from '!raw-loader!./icon-gear.svg'
import iconOrder from '!raw-loader!./icon-order.svg'
import iconCross from '!raw-loader!./icon-cross.svg'
import iconMenu from '!raw-loader!./icon-menu.svg'

import wave from '!raw-loader!./wave.svg'
import whiteWave from '!raw-loader!./white-wave.svg'
import blueWave from '!raw-loader!./blue-wave.svg'

import triangleOpen from '!raw-loader!./triangle-open.svg'
import triangleClose from '!raw-loader!./triangle-close.svg'
import iconLock from '!raw-loader!./lock.svg'

import truckOk from '!raw-loader!./truck-ok.svg'
import userOk from '!raw-loader!./user-ok.svg'

import iconDanger from '!raw-loader!./icon-danger.svg'

import iconCancelledSail from '!raw-loader!./cancelled-sail.svg'

// Login/Sign-up

import iconEmailAuth from '!raw-loader!./icon-email-auth.svg'
import iconEmailAuthActive from '!raw-loader!./icon-email-auth-active.svg'
import iconSmartIdAuth from '!raw-loader!./icon-smartID-auth.svg'
import iconSmartIdAuthActive from '!raw-loader!./icon-smartID-active.svg'
import smartIdActive from '!raw-loader!./smartID-active.svg'
import iconFBAuth from '!raw-loader!./icon-fb-auth.svg'
import iconFBActive from '!raw-loader!./icon-fb-active.svg'
import iconGoogleAuth from '!raw-loader!./icon-google-auth.svg'
import iconGoogleActive from '!raw-loader!./icon-google-active.svg'

import iconUAEPassAuth from '!raw-loader!./icon-uaepass-round.svg'
import iconUAEPassAuthActive from '!raw-loader!./icon-uaepass-active-round.svg'

// Profile
import iconMoreDots from '!raw-loader!./icon-more-dots.svg'
import iconCard from '!raw-loader!./icon-card.svg'
import iconBoughtCash from '!raw-loader!./icon-cash.svg'
import iconBoughtCredit from '!raw-loader!./icon-bought-credit.svg'
import iconOnline from '!raw-loader!./icon-online.svg'
import iconKiosk from '!raw-loader!./icon-kiosk.svg'
import iconOnBoard from '!raw-loader!./icon-onboard.svg'
import iconHandShake from '!raw-loader!./icon-handshake.svg'
import iconSelectedTab from '!raw-loader!./icon-selected-tab.svg'
import iconArrowUp from '!raw-loader!./icon-arrow-up.svg'
import iconArrowDown from '!raw-loader!./icon-arrow-down.svg'

// Seajets
import iconPricelist_seajets from '!raw-loader!./pricelist_seajets.svg'
import iconHelp_seajets from '!raw-loader!./help_seajets.svg'
import iconPiontOk_seajets from '!raw-loader!./check_seajets.svg'
import iconPointCheck_seajets from '!raw-loader!./check-active_seajets.svg'
import iconPointEmpty_seajets from '!raw-loader!./check-empty_seajets.svg'

// Posidonia
import iconHelp_posidonia from '!raw-loader!./help_posidonia.svg'
import iconPiontOk_posidonia from '!raw-loader!./check_posidonia.svg'
import iconPointCheck_posidonia from '!raw-loader!./check-active_posidonia.svg'
import iconPointEmpty_posidonia from '!raw-loader!./check-empty_posidonia.svg'
import iconPricelist_posidonia from '!raw-loader!./pricelist_posidonia.svg'

// GPLines
import iconHelp_gplines from '!raw-loader!./help_posidonia.svg'
import iconPiontOk_gplines from '!raw-loader!./check_posidonia.svg'
import iconPointCheck_gplines from '!raw-loader!./check-active_posidonia.svg'
import iconPointEmpty_gplines from '!raw-loader!./check-empty_posidonia.svg'
import iconPricelist_gplines from '!raw-loader!./pricelist_posidonia.svg'
import iconDirections_gplines from '!raw-loader!./directions_gplines.svg'
import seagull from '!raw-loader!./seagull.svg'

//Gavdos
import iconHelp_gavdos from '!raw-loader!./help_gavdos.svg'
import iconPiontOk_gavdos from '!raw-loader!./check_gavdos.svg'
import iconPointCheck_gavdos from '!raw-loader!./check-active_gavdos.svg'
import iconPointEmpty_gavdos from '!raw-loader!./check-empty_gavdos.svg'
import iconPricelist_gavdos from '!raw-loader!./pricelist_gavdos.svg'

import iconArrow_gavdos from '!raw-loader!./icon-gavdos-arrow.svg'

import iconDirections_gavdos from '!raw-loader!./directions_gplines.svg'
import iconBicycle_gavdos from '!raw-loader!./icon-gavdos-bicycle.svg'
import iconBus_gavdos from '!raw-loader!./icon-gavdos-bus.svg'
import iconLunch_gavdos from '!raw-loader!./icon-gavdos-lunch.svg'
import iconMail_gavdos from '!raw-loader!./icon-gavdos-mail.svg'
import iconPhone_gavdos from '!raw-loader!./icon-gavdos-phone.svg'
import iconShip_gavdos from '!raw-loader!./icon-gavdos-ship.svg'
import iconTicket_gavdos from '!raw-loader!./icon-gavdos-ticket.svg'
import iconUser_gavdos from '!raw-loader!./icon-gavdos-user.svg'
import iconTraveller_gavdos from '!raw-loader!./icon-gavdos-traveller.svg'

//Maritime
import iconHelp_admaritime from '!raw-loader!./icon-help.svg'
import iconPiontOk_admaritime from '!raw-loader!./check_admaritime.svg'
import iconPointCheck_admaritime from '!raw-loader!./check-active_admaritime.svg'
import iconPointEmpty_admaritime from '!raw-loader!./check-empty_admaritime.svg'
import iconPricelist_admaritime from '!raw-loader!./icon-pricelist.svg'

import iconHelp_bluewave from '!raw-loader!./icon-help.svg'
import iconPiontOk_bluewave from '!raw-loader!./check_admaritime.svg'
import iconPointCheck_bluewave from '!raw-loader!./check-active_admaritime.svg'
import iconPointEmpty_bluewave from '!raw-loader!./check-empty_admaritime.svg'
import iconPricelist_bluewave from '!raw-loader!./icon-pricelist.svg'

import iconDirections_admaritime from '!raw-loader!./icon-directions_admaritime.svg'
import iconPassenger_admaritime from '!raw-loader!./icon-passenger_admaritime.svg'
import iconBicycle_admaritime from '!raw-loader!./icon-bicycle_admaritime.svg'
import iconCar_admaritime from '!raw-loader!./icon-car_admaritime.svg'
import iconSafePurchase from '!raw-loader!./icon-safe-purchase.svg'
import iconSafetyOnboard from '!raw-loader!./icon-safety-onboard.svg'
import iconFooterLogo_admaritime from '!raw-loader!./icon-footer-logo_admaritime.svg'
import iconFooterMobileLogo_admaritime from '!raw-loader!./icon-footer-mobile-logo_admaritime.svg'
import iconFacebook_admaritime from '!raw-loader!./icon-facebook_admaritime.svg'
import iconInstagram_admaritime from '!raw-loader!./icon-instagram_admaritime.svg'
import iconLinkedin_admaritime from '!raw-loader!./icon-linkedin_admaritime.svg'
import iconTwitter_admaritime from '!raw-loader!./icon-x-white.svg'
import iconSubscription_admaritime from '!raw-loader!./icon-subscription_admaritime.svg'
import iconEmail_admaritime from '!raw-loader!./icon-email_admaritime.svg'
import iconPhone_admaritime from '!raw-loader!./icon-phone_admaritime.svg'
import iconLocation_admaritime from '!raw-loader!./icon-location_admaritime.svg'
import iconArrowDown_admaritime from '!raw-loader!./icon-arrow-down_admaritime.svg'
import iconSelected_admaritime from '!raw-loader!./icon-selected_admaritime.svg'
import iconCalendar_admaritime from '!raw-loader!./icon-calendar_admaritime.svg'
import iconLock_admaritime from '!raw-loader!./icon-lock_admaritime.svg'
import iconRegister_admaritime from '!raw-loader!./icon-register_admaritime.svg'
import iconLockSelected_admaritime from '!raw-loader!./icon-lock-selected_admaritime.svg'
import iconRegisterSelected_admaritime from '!raw-loader!./icon-register-selected_admaritime.svg'
import iconFieldLock from '!raw-loader!./icon-field-lock.svg'
import iconFieldEmail from '!raw-loader!./icon-field-email.svg'
import iconAtSignActive from '!raw-loader!./icon-at-sign-active.svg'
import iconAtSign from '!raw-loader!./icon-at-sign.svg'
import iconFBActive_admaritime from '!raw-loader!./icon-fb-active_admaritime.svg'
import iconFBAuth_admaritime from '!raw-loader!./icon-fb-auth_admaritime.svg'
import iconFB_admaritime from '!raw-loader!./icon-fb_admaritime.svg'
import iconAdult_admaritime from '!raw-loader!./icon-adult_admaritime.svg'
import iconCartActive_admaritime from '!raw-loader!./icon-cart-active_admaritime.svg'
import iconCart_admaritime from '!raw-loader!./icon-cart_admaritime.svg'
import iconChildren_admaritime from '!raw-loader!./icon-children_admaritime.svg'
import iconSenior_admaritime from '!raw-loader!./icon-senior_admaritime.svg'
import iconDisabled_admaritime from '!raw-loader!./icon-disabled_admaritime.svg'
import iconAssistant_admaritime from '!raw-loader!./icon-assistant_admaritime.svg'
import iconLuggage_admaritime from '!raw-loader!./icon-luggage_admaritime.svg'
import iconTrailer_admaritime from '!raw-loader!./icon-trailer_admaritime.svg'
import iconCharter_admaritime from '!raw-loader!./icon-charter_admaritime.svg'
import iconVehicle_admaritime from '!raw-loader!./icon-vehicle_admaritime.svg'
import iconCircleOk_admaritime from '!raw-loader!./icon-point-ok_admaritime.svg'
import iconCustomer_admaritime from '!raw-loader!./icon-customer_admaritime.svg'
import iconECommercePaymentMethod_admaritime from '!raw-loader!./icon-e-commerce-payment-method_admaritime.svg'
import iconQuestionMark_admaritime from '!raw-loader!./icon-question-mark_admaritime.svg'
import iconWaterTaxi_admaritime from '!raw-loader!./icon-water-taxi_admaritime.svg'
import iconBookTickets_admaritime from '!raw-loader!./icon-book-tickets_admaritime.svg'
import iconPassengersData_admaritime from '!raw-loader!./icon-passengers-data_admaritime.svg'
import iconPlus_admaritime from '!raw-loader!./icon-plus_admaritime.svg'
import iconSearch_admaritime from '!raw-loader!./icon-search_admaritime.svg'
import iconGroup_admaritime from '!raw-loader!./icon-group_admaritime.svg'
import iconTableArrowDown_admaritime from '!raw-loader!./icon-table-arrow-down_admaritime.svg'
import iconTruck_admaritime from '!raw-loader!./icon-truck_admaritime.svg'
import iconBannerTruck_admaritime from '!raw-loader!./icon-banner-truck_admaritime.svg'

// StarLite
import iconHelp_starlite from '!raw-loader!./help_posidonia.svg'
import iconPiontOk_starlite from '!raw-loader!./check_posidonia.svg'
import iconPointCheck_starlite from '!raw-loader!./check-active_posidonia.svg'
import iconPointEmpty_starlite from '!raw-loader!./check-empty_posidonia.svg'
import iconPricelist_starlite from '!raw-loader!./pricelist_posidonia.svg'
import iconDirections_starlite from '!raw-loader!./directions_gplines.svg'

// 2GO
import iconHelp_2go from '!raw-loader!./help_posidonia.svg'
import iconPiontOk_2go from '!raw-loader!./icon-point-ok.svg'
import iconPointCheck_2go from '!raw-loader!./icon-point-check.svg'
import iconPointEmpty_2go from '!raw-loader!./icon-point-empty.svg'
import iconPricelist_2go from '!raw-loader!./pricelist_posidonia.svg'
import traveller_2go from '!raw-loader!./traveller_2go.svg'
import trailer_2go from '!raw-loader!./trailer_2go.svg'
import vehicle_2go from '!raw-loader!./vehicle_2go.svg'
import bicycle_2go from '!raw-loader!./bicycle_2go.svg'
import iconArrow_2go from '!raw-loader!./user-copy_2go.svg'
import ship_2go from '!raw-loader!./ship_2go.svg'
import loop_2go from '!raw-loader!./loop_2go.svg'

// Saaremaa
import traveller_saaremaa from '!raw-loader!./traveller_saaremaa.svg'
import bicycle_saaremaa from '!raw-loader!./bicycle_saaremaa.svg'
import vehicle_saaremaa from '!raw-loader!./vehicle_saaremaa.svg'
import trailer_saaremaa from '!raw-loader!./trailer_saaremaa.svg'
import sail_selected_saaremaa from '!raw-loader!./icon-sail-selected_saaremaa.svg'

// Bluewave
import iconFooterLogo_bluewave from '!raw-loader!./icon-footer-logo_bluewave.svg'
import iconHeaderLogo_bluewave from '!raw-loader!./icon-header-logo_bluewave.svg'

// Sunlines
import iconHelp_sunlines from '!raw-loader!./help_posidonia.svg'
import iconPricelist_sunlines from '!raw-loader!./pricelist_posidonia.svg'
import iconPiontOk_sunlines from '!raw-loader!./icon-point-ok_sunlines.svg'
import iconPointCheck_sunlines from '!raw-loader!./icon-point-check_sunlines.svg'
import iconPointEmpty_sunlines from '!raw-loader!./icon-point-empty.svg'
import iconArrow_sunlines from '!raw-loader!./icon-arrow-sunlines.svg'
import trailer_sunlines from '!raw-loader!./trailer_sunlines.svg'
import vehicle_sunlines from '!raw-loader!./vehicle_sunlines.svg'
import bicycle_sunlines from '!raw-loader!./bicycle_sunlines.svg'

import snapShip from '!raw-loader!./snapShip.svg'
import stop from '!raw-loader!./stop.svg'
import time from '!raw-loader!./time.svg'
import paperBin from '!raw-loader!./throw-to-paper-bin.svg'

import dropDownLittleTriangle from '!raw-loader!./drop-down-little-triangle.svg'
import ticketsCouple from '!raw-loader!./park-tickets-couple.svg'
import crown from '!raw-loader!./crown.svg'

import sadFace from '!raw-loader!./iconmonstr-smiley-24.svg'

export default {
  arrow,
  search,
  cancel,
  add,
  remove,
  selectTicketArrow,
  applePay,
  iconArrow,
  mastercard,
  visa,
  iconChevronDown,
  iconChevronDown_saaremaa,
  iconChevronDownLang,
  iconAuto,
  iconAutoTicket,
  iconPassenger,
  iconPassengerTicket,
  iconTruck,
  iconTruckTicket,
  plusInactive,
  foliage,
  video,
  iconClose,
  iconGoogle,
  iconFB,
  iconSmartId,
  iconSmartIdColored,
  iconSmartIdLogo,
  iconEye,
  iconSignout,
  iconLoyalty,
  iconDirections,
  defaultUser,
  iconDanger,
  iconCancelledSail,
  iconApplePay,

  iconWarningTriangle,
  iconSnowflake,
  iconHovercraft,

  iconPhone,
  iconPerson,
  iconEmail,

  russian,
  english,
  estonian,
  greek,
  philippines,
  german,
  polish,
  swedish,
  spanish,
  iconHelp_demo,

  iconPricelist_gavdos,
  iconArrow_gavdos,
  iconPiontOk_gavdos,
  iconPointCheck_gavdos,
  iconHelp_gavdos,
  iconPointEmpty_gavdos,
  iconDirections_gavdos,
  iconBicycle_gavdos,
  iconBus_gavdos,
  iconLunch_gavdos,
  iconMail_gavdos,
  iconPhone_gavdos,
  iconShip_gavdos,
  iconTicket_gavdos,
  iconTraveller_gavdos,
  iconUser_gavdos,

  iconHelp_admaritime,
  iconPiontOk_admaritime,
  iconPointCheck_admaritime,
  iconPointEmpty_admaritime,
  iconPricelist_admaritime,
  iconDirections_admaritime,
  iconPassenger_admaritime,
  iconBicycle_admaritime,
  iconCar_admaritime,
  iconSafePurchase,
  iconSafetyOnboard,
  iconFooterLogo_admaritime,
  iconFooterMobileLogo_admaritime,
  iconFacebook_admaritime,
  iconInstagram_admaritime,
  iconLinkedin_admaritime,
  iconTwitter_admaritime,
  iconEmail_admaritime,
  iconSubscription_admaritime,
  iconPhone_admaritime,
  iconLocation_admaritime,
  iconArrowDown_admaritime,
  iconSelected_admaritime,
  iconCalendar_admaritime,
  iconLock_admaritime,
  iconRegister_admaritime,
  iconLockSelected_admaritime,
  iconRegisterSelected_admaritime,
  iconFieldLock,
  iconFieldEmail,
  iconAtSignActive,
  iconFBActive_admaritime,
  iconAtSign,
  iconFBAuth_admaritime,
  iconFB_admaritime,
  iconCartActive_admaritime,
  iconCart_admaritime,
  iconAdult_admaritime,
  iconChildren_admaritime,
  iconSenior_admaritime,
  iconDisabled_admaritime,
  iconAssistant_admaritime,
  iconLuggage_admaritime,
  iconTrailer_admaritime,
  iconCharter_admaritime,
  iconVehicle_admaritime,
  iconCircleOk_admaritime,
  iconCustomer_admaritime,
  iconECommercePaymentMethod_admaritime,
  iconQuestionMark_admaritime,
  iconWaterTaxi_admaritime,
  iconBookTickets_admaritime,
  iconPassengersData_admaritime,
  iconPlus_admaritime,
  iconSearch_admaritime,
  iconGroup_admaritime,
  iconTableArrowDown_admaritime,
  iconTruck_admaritime,
  iconBannerTruck_admaritime,

  iconChevron,
  iconPricelist_kihnu,
  iconPricelist_saaremaa,
  iconPricelist_tallink,
  iconHelp_kihnu,
  iconHelp_saaremaa,
  iconHelp_tallink,
  iconSwapDirections,
  iconExchangeArrows,
  iconReturnRoute,
  iconGoBack,
  iconGoBackBold,
  iconTime,
  iconTimerRunning,

  iconShip,
  iconCar,
  iconCarLL,
  iconCabin,
  iconDishes,
  iconCarsHeight,
  iconCarsWeight,
  iconCarsLength,
  iconBike,
  iconLightWeight,
  iconMicrobus,
  iconMotorcycle,
  iconRefund,
  iconPrinter,
  iconDownload,
  iconTrailer,
  iconBigTruckTrailer,
  iconHeavyWeightTrailer,
  iconTransfer,
  iconWifi,
  iconFood,
  iconEdit,
  iconEditMui,
  iconCheck,
  iconImportant,
  iconQuestion,
  iconPiontOk_kihnu,
  iconPiontOk_saaremaa,
  iconPiontOk_tallink,
  iconPiontOkRed,
  iconPointCheck_kihnu,
  iconPointCheck_saaremaa,
  iconPointCheck_tallink,
  iconPointEmpty_kihnu,
  iconPointEmpty_saaremaa,
  iconPointEmpty_tallink,
  pathOk,
  pathDotted,
  triangle,
  check,
  trailer,
  iconCarStamp,
  iconPencil,
  iconUser,
  iconCompany,
  iconState,
  iconKey,
  iconPassword,
  iconWheelchairAccess,
  iconGear,
  iconOrder,
  iconCross,
  iconShape,
  iconMenu,
  iconCombinedShape,

  wave,
  whiteWave,
  blueWave,
  triangleOpen,
  triangleClose,
  iconLock,
  smartId,
  iconSmartIdAuthActive,
  iconSmartIdAuth,
  smartIdActive,
  iconFBAuth,
  iconFBActive,
  iconGoogleActive,
  iconGoogleAuth,
  iconEmailAuth,
  iconEmailAuthActive,
  iconMoreDots,
  iconCard,
  iconOnline,
  iconKiosk,
  iconHandShake,
  iconOnBoard,
  iconBoughtCredit,
  iconBoughtCash,
  iconSelectedTab,

  // Seajets
  iconPricelist_seajets,
  iconHelp_seajets,
  iconPiontOk_seajets,
  iconPointCheck_seajets,
  iconPointEmpty_seajets,

  // Posidonia
  iconPricelist_posidonia,
  iconHelp_posidonia,
  iconPiontOk_posidonia,
  iconPointCheck_posidonia,
  iconPointEmpty_posidonia,

  // GPLines
  iconPricelist_gplines,
  iconHelp_gplines,
  iconPiontOk_gplines,
  iconPointCheck_gplines,
  iconPointEmpty_gplines,
  iconDirections_gplines,
  seagull,

  // StarLite
  iconPricelist_starlite,
  iconHelp_starlite,
  iconPiontOk_starlite,
  iconPointCheck_starlite,
  iconPointEmpty_starlite,
  iconDirections_starlite,

  // 2GO
  iconPricelist_2go,
  iconHelp_2go,
  iconPiontOk_2go,
  iconPointCheck_2go,
  iconPointEmpty_2go,
  traveller_2go,
  vehicle_2go,
  bicycle_2go,
  trailer_2go,
  iconArrow_2go,
  ship_2go,
  loop_2go,

  // Saaremaa
  traveller_saaremaa,
  bicycle_saaremaa,
  vehicle_saaremaa,
  trailer_saaremaa,
  sail_selected_saaremaa,

  // Sunlines
  iconHelp_sunlines,
  iconPricelist_sunlines,
  iconPiontOk_sunlines,
  iconPointCheck_sunlines,
  iconPointEmpty_sunlines,
  iconArrow_sunlines,
  trailer_sunlines,
  vehicle_sunlines,
  bicycle_sunlines,

  snapShip,
  stop,
  time,
  paperBin,

  dropDownLittleTriangle,
  ticketsCouple,
  crown,
  iconArrowUp,
  iconArrowDown,
  iconErrorFilled,
  sadFace,
  iconNoHeavyTrucks,
  iconBidirectional,
  iconPricelist_liinilaevad,
  iconPointCheck_liinilaevad,
  iconPointEmpty_liinilaevad,
  iconPiontOk_liinilaevad,
  iconPhoneLL,
  iconMailLL,
  iconWarningLL,
  iconWarningWhiteLL,
  iconExpandLL,
  iconCollapseLL,
  iconUAEPassAuth,
  iconUAEPassAuthActive,

  // Bluewave
  iconFooterLogo_bluewave,
  iconHeaderLogo_bluewave,
  iconHelp_bluewave,
  iconPiontOk_bluewave,
  iconPointCheck_bluewave,
  iconPointEmpty_bluewave,
  iconPricelist_bluewave,
  truckOk,
  userOk,
}
