import React from 'react'
import PropTypes from 'prop-types'
import RaisedButton from 'material-ui/RaisedButton'
import FloatingActionButton from 'material-ui/FloatingActionButton'
import IconButton from '@material-ui/core/IconButton'
import styled from 'styled-components'

import { objToString } from '../utils/styles'
import { isLiinilaevad } from 'bora/src/utils/liinilaevadThemeUtils'
import { THEME } from '../../themes'

const isSaarema = window.brandProps.theme === THEME.SAAREMAA

const StyledFloatingActionButton = styled(FloatingActionButton)`
  margin-right: 0;
  box-shadow: ${(props) =>
    window.brandProps.theme === THEME.LIINILAEVAD
      ? 'none !important'
      : `${props.disabled ? 'none' : props.boxShadow} !important`};

  button {
    background: ${(props) =>
    `${props.disabled ? (isLiinilaevad ? props.disabledBg : 'transparent') : props.background} !important`};
    width: ${(props) => `${props.width} !important`};
    height: ${(props) => `${props.height} !important`};
    border: ${(props) =>
    `${
      props.disabled ? `2px solid ${isLiinilaevad ? '#ffffff' : props.disabledBg}` : props.border || 'none'
    } !important`};
  }

  button div {
    display: flex;
    width: 100%;
    height: 100%;
    font-size: 0px;
  }

  button svg {
    margin: auto !important;
    fill: ${(props) => `${props.disabled ? (isLiinilaevad ? '#ffffff' : props.disabledBg) : props.fill} !important`};
    //height: ${(props) => `${props.height} !important`};
  }
`
StyledFloatingActionButton.defaultProps = {
  width: '36px',
  height: '36px',
  background: isLiinilaevad ? '#88CC33' : isSaarema ? '#00CCA0' : '#20bbd1',
  disabledBg: isLiinilaevad ? '#A8A8A8' : '#d9d9d9',
  fill: 'white',
  boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 0 0.3px rgba(0, 0, 0, 0.02)',
}

const StyledIconButton = styled(IconButton)`
  width: ${(props) => `${props.width} !important`};
  height: ${(props) => `${props.height} !important`};
  margin: ${(props) => `${props.margin} !important`};
  padding: ${(props) => `${props.padding} !important`};
  min-width: ${(props) => `${props.minWidth} !important`};

  &:hover {
    background-color: transparent !important;
  }
`
StyledIconButton.defaultProps = {
  width: '58px',
  margin: '0',
  padding: '0',
}

const StyledRaisedButtonIcon = styled(RaisedButton)``
StyledRaisedButtonIcon.defaultProps = {}

// ----------------------------------------------------------- square button            -------------------------
const StyledRaisedButton = styled(RaisedButton)`
  width: ${(props) => `${props.width} !important`};
  height: ${(props) => `${props.height} !important`};
  margin: ${(props) => `${props.margin} !important`};
  padding: ${(props) => `${props.padding} !important`};
  box-shadow: ${(props) => (isLiinilaevad ? 'none !important' : `${props.boxShadow} !important`)};
  border-radius: ${(props) => `${props.borderRadius} !important`};
  border: ${(props) => `${props.border} !important`};
  background-color: ${(props) => `${props.background} !important`};
  min-width: ${(props) => `${props.minWidth} !important`};

  ${(props) => props.hovering && objToString(props.hoverStyles)}

  &:hover {
    ${(props) => objToString(props.hoverStyles)}
  }

  button {
    height: 100% !important;
    line-height: ${(props) => props.lineHeight};
    color: ${(props) => `${props.color} !important`};
    border-radius: ${(props) => `${props.borderRadius} !important`};
    background-color: ${(props) => `${props.background} !important`};
  }

  button > div > div {
    background-color: ${(props) => (props.background === 'transparent' ? 'transparent !important' : 'initial')};
  }
  button > div,
  button > div > div {
    //display: flex !important;
    height: 100% !important;
  }
`

StyledRaisedButton.defaultProps = {
  width: '367px',
  height: '88px',
  margin: '0',
  background: '#000',
  color: 'white',
  boxShadow: '0 11px 6px 0 rgba(0, 0, 0, 0.04)',
}
// ----------------------------------------------------------- square button   DISABLED -------------------------
const StyledRaisedButtonDisabled = styled(RaisedButton)`
  width: ${(props) => `${props.width} !important`};
  height: ${(props) => `${props.height} !important`};
  margin: ${(props) => `${props.margin} !important`};
  box-shadow: ${(props) => (isLiinilaevad ? 'none !important' : `${props.boxShadow} !important`)};
  border-radius: ${(props) => `${props.borderRadius} !important`};
  background-color: ${(props) => `${props.background} !important`};
  min-width: ${(props) => `${props.minWidth} !important`};

  button {
    height: 100% !important;
    color: ${(props) => `${props.color} !important`};
    border-radius: ${(props) => `${props.borderRadius} !important`};
    background-color: ${(props) => `${props.disabledBg} !important`};
  }

  button > div,
  button > div > div {
    //display: flex !important;
    height: 100% !important;
  }
`
StyledRaisedButtonDisabled.defaultProps = {
  width: StyledRaisedButton.defaultProps.width,
  height: StyledRaisedButton.defaultProps.height,
  margin: StyledRaisedButton.defaultProps.margin,
  disabledBg: isLiinilaevad ? '#A8A8A8' : '#c2c2c2',
  background: isLiinilaevad ? '#A8A8A8' : '#c2c2c2',
  color: StyledRaisedButton.defaultProps.color,
  boxShadow: StyledRaisedButton.defaultProps.boxShadow,
}

const getButtonParameters = (props, context) => {
  const { muiTheme } = context
  const { id = 'button', click, ...params } = props
  let customThemeProps = {}
  if (muiTheme) customThemeProps = muiTheme.ids[id] || {}

  const defaultProps = {
    label: '',
    // onTouchTap: click,
    onClick: click,
    mini: 'true',
    disabled: false,
  }

  const internals = {
    ...defaultProps,
    ...customThemeProps,
    ...params,
  }

  return internals
}

// eslint-disable-next-line react/prefer-stateless-function
class Button extends React.Component {
  render() {
    const { type, isRender, isMobile, borderRadius, buttonType, ...params } = getButtonParameters(
      this.props,
      this.context
    )

    switch (type) {
      case 'circle':
        return <StyledFloatingActionButton type={buttonType} {...params} />
      case 'icon':
        return <StyledIconButton type={buttonType} tooltip={params.label} {...params} style={params.style} />
      default: {
        const { disabled } = params

        if (disabled) {
          return <StyledRaisedButtonDisabled {...params} borderRadius={borderRadius} />
        }
        return <StyledRaisedButton type={buttonType} {...params} borderRadius={borderRadius} />
      }
    }
  }
}

Button.contextTypes = {
  muiTheme: PropTypes.object,
}

Button.propTypes = {}

export default Button
