import { THEME } from '../../themes'

require('react').PropTypes = require('prop-types')
const { Box } = require('reflexbox')

import React, { Children } from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { UILayout } from '../UI'
import { objToString } from '../utils/styles'

const isHidden = (props, type, breakpoints) => {
  const types = Object.keys(breakpoints)
  const index = types.indexOf(type)
  const rest = types.splice(index, types.length - index)
  const nearType = rest.find((t) => props[t] !== undefined)
  return nearType !== null ? props[nearType] === 0 : false
}

function responsiveHide(type, themeBreakpoints) {
  return (props) => {
    if (!themeBreakpoints) {
      return 'display: flex;\n'
    }

    const hidden = isHidden(props, type, props.breakpoints)
    return breakpoint(type)`display: ${() => (props.display ? props.display : hidden ? 'none' : 'flex')};`
  }
}

const allResponsiveHide = (breakpoints, themeBreakpoints) =>
  Object.keys(breakpoints).map((type) => responsiveHide(type, themeBreakpoints))

const WrapBox = (props) => {
  const { breakpoints, children, ...restProps } = props

  Object.keys(breakpoints).forEach((_, i) => {
    delete restProps[i]
  })

  return <Box {...restProps}>{children}</Box>
}

WrapBox.propTypes = {
  breakpoints: PropTypes.any.isRequired,
  children: PropTypes.any,
}

const blink = keyframes`
  0% { opacity: 0.5; }
  100% { opacity: 1; }
`

function filterStyledProps(props) {
  const {
    alignSelf,
    bg,
    bgImage,
    bgColor,
    bgSize,
    blink,
    border,
    borderBottom,
    borderRadius,
    borderRight,
    borderTop,
    bottom,
    boxShadow,
    cursor,
    display,
    flexDirection,
    height,
    hoverStyles,
    left,
    lineHeight,
    margin,
    maxWidth,
    maxHeight,
    minHeight,
    minWidth,
    opacity,
    outline,
    overflow,
    overflowY,
    padding,
    pointerEvents,
    position,
    right,
    shrink,
    textAlign,
    top,
    transition,
    whiteSpace,
    width,
    zIndex,
    ...filteredStyledProps
  } = props

  return filteredStyledProps
}

const Size = styled((props) => <WrapBox {...filterStyledProps(props)} />)`
  display: ${(props) => props.display};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  right: ${(props) => props.right};
  left: ${(props) => props.left};
  bottom: ${(props) => props.bottom};
  top: ${(props) => props.top};
  position: ${(props) => props.position};
  z-index: ${(props) => props.zIndex};
  background-color: ${(props) => props.bgColor};
  background-size: ${(props) => props.bgSize || 'contain'};
  line-height: ${(props) => props.lineHeight};
  white-space: ${(props) => props.whiteSpace};
  min-width: ${(props) => `${props.minWidth} !important`};
  min-height: ${(props) => `${props.minHeight} !important`};
  max-width: ${(props) => props.maxWidth};
  max-height: ${(props) => props.maxHeight};
  opacity: ${(props) => props.opacity};
  cursor: ${(props) => props.cursor};
  border: ${(props) => props.border};
  box-shadow: ${(props) => (window.brandProps.theme === THEME.LIINILAEVAD ? 'none !important' : props.boxShadow)};
  border-radius: ${(props) => props.borderRadius};
  border-bottom: ${(props) => props.borderBottom};
  border-right: ${(props) => props.borderRight};
  border-left: ${(props) => props.borderLeft};
  border-top: ${(props) => props.borderTop};
  overflow: ${(props) => props.overflow};
  overflow-y: ${(props) => props.overflowY};
  outline: ${(props) => props.outline};
  transition: ${(props) => props.transition};
  transform: ${(props) => props.transform};
  flex-shrink: ${(props) => props.shrink};
  text-align: ${(props) => props.textAlign};
  align-self: ${(props) => props.alignSelf};
  pointer-events: ${(props) => props.pointerEvents};
  flex-direction: ${(props) => props.flexDirection};

  &:hover {
    ${(props) => objToString(props.hoverStyles)}
  }

  ${(props) =>
    props.blink
      ? css`
          animation: ${blink} 0.8s linear infinite;
        `
      : ''}
  ${(props) => (props.bg !== 'false' ? `background: url(${props.bg});` : '')}
  ${(props) => (props.bgImage !== 'false' ? `background: url(${props.bgImage}) !important; background-size: cover !important;` : '')}
  ${(props) => allResponsiveHide(props.breakpoints, props.theme.breakpoints)}
`

Size.defaultProps = {
  width: '100%',
  height: 'auto',
  margin: 0,
  padding: 0,
  bg: 'false',
  bgImage: 'false',
  bgColor: 'transparent',
  lineHeight: 'inherit',
  minWidth: 'auto',
  maxWidth: 'auto',
  border: 'none',
  borderRadius: '0',
  overflow: 'visible',
  transform: 'none',
  shrink: '1',
}

// eslint-disable-next-line react/prefer-stateless-function
class Layout extends React.Component {
  render() {
    const {
      props,
      context: { muiTheme },
    } = this

    const { id } = props
    const customThemeProps = muiTheme.ids[id] || {}

    const internals = {
      ...customThemeProps,
      ...props,
      ...{
        flexColumn: props.column,
      },
      ...(props.center && { align: 'center' }),
      ...(props['flex-start'] && { align: 'flex-start' }),
      ...(props['flex-end'] && { align: 'flex-end' }),
      ...(props['j-flex-start'] && { justify: 'flex-start' }),
      ...(props['j-flex-end'] && { justify: 'flex-end' }),
      ...(props['j-flex-center'] && { justify: 'center' }),
      ...(props['j-flex-space-between'] && { justify: 'space-between' }),
      ...(props['flex-wrap'] && { wrap: 'wrap' }),
    }

    delete internals.hovering
    delete internals.isRender
    delete internals.isMobile
    delete internals.center
    delete internals['flex-end']
    delete internals['j-flex-end']
    delete internals['flex-center']
    delete internals['j-flex-start']
    delete internals['j-flex-center']
    delete internals['j-flex-space-between']
    delete internals.children
    delete internals.childrenStyle

    return (
      <Size {...internals} breakpoints={this.context.muiTheme.breakpoints} theme={this.context.theme}>
        {props.childrenStyle
          ? Children.map(props.children, (child) => <UILayout {...props.childrenStyle}>{child}</UILayout>)
          : props.children}
      </Size>
    )
  }
}

Layout.contextTypes = {
  muiTheme: PropTypes.object,
  theme: PropTypes.object,
}

export default Layout
