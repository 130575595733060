import React from 'react'
import PropTypes from 'prop-types'

import { UIText, UILayout, UIImage } from '../../../UI'

const GroupHeader = (props) => (
  <UILayout>
    <UILayout column j-flex-start padding="27px 0 30px 0" xs={0} xl={1}>
      <UIText size="18px" color="#000000" weight="bold" align="left" margin="0 0 2px 0">{props.name}</UIText>
      <UIText size="18px" color="#000000" align="left">{props.description}</UIText>
    </UILayout>
    <UILayout
      xs={1}
      xl={0}
      center
      column
      bg={props.image}
      j-flex-center
      height="96px"
      width="100%"
    >
      <UIText size="36px" color="white" align="center" textTransform="uppercase">{props.name}</UIText>
    </UILayout>
  </UILayout>
)

GroupHeader.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

export default GroupHeader
