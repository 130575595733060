/* eslint-disable no-nested-ternary */
import React from 'react'
import { range } from 'ramda'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Step, Stepper as StepperMaterialUI, StepButton } from 'material-ui/Stepper'
import { UIIcon, UILayout } from '../UI'
import { THEME } from '../../themes'
import moduleStyles from './Stepper.module.scss'
import { getMaritimeStyle } from 'bora/src/utils/maritimeStyleUtils'

// eslint-disable-next-line no-param-reassign
const removeUndefinedKeys = (obj) => Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key])

const StyledStepButton = styled(StepButton)`
  background-color: transparent !important;

  & > div > span,
  & > span {
    height: 100% !important;
    padding: 0 !important;
    flex-direction: column !important;
    align-items: flex-start !important;
  }
`
StyledStepButton.defaultProps = {}

const getStepperParams = (props, context) => {
  const { muiTheme } = context
  const { id = 'stepper', error, ...params } = props
  const customThemeProps = muiTheme.ids[id] || {}

  const defaultProps = {
    stepsCount: 2,
    activeStep: 0,
    minStep: 0,
    error: false,
  }

  removeUndefinedKeys(params)

  const internals = {
    ...defaultProps,
    ...customThemeProps,
    ...params,
  }

  return internals
}

const styles = {
  step: { margin: '0' },
  overflow: { overflow: 'hidden' },
  iconContainerStyle: { padding: '0' },
}

const StepIcon = (props, { muiTheme }) => (
  <UILayout center>
    <UILayout column center padding="0 9px" position="relative" {...muiTheme.ids.stepContainer}>
      {props.icon ? (
        <UIIcon type={props.icon} />
      ) : props.active ? (
        <UIIcon type={`iconPointCheck_${props.theme}`} />
      ) : props.completed ? (
        <UIIcon type={!props.error ? `iconPiontOk_${props.theme}` : 'iconPiontOkRed'} />
      ) : (
        <UIIcon type={`iconPointEmpty_${props.theme}`} stroke={props.strokeColor} />
      )}
      <UILayout width="auto">
        <UILayout
          fontWeight={props.active ? 'bold' : 'normal'}
          position="absolute"
          transform="translateX(-50%)"
          left="50%"
          width="auto"
          {...muiTheme.ids.stepLabel}
        >
          {props.label}
        </UILayout>
      </UILayout>
    </UILayout>
  </UILayout>
)

StepIcon.propTypes = {
  active: PropTypes.bool.isRequired,
  completed: PropTypes.bool.isRequired,
  icon: PropTypes.any,
  theme: PropTypes.string,
  label: PropTypes.any,
  strokeColor: PropTypes.string,
  error: PropTypes.any,
}

StepIcon.contextTypes = {
  muiTheme: PropTypes.any,
}

StepIcon.defaultProps = {
  strokeColor: '#000000',
  theme: THEME.KIHNU,
  error: false,
}

const xsLineStyles = {
  width: getMaritimeStyle('50px'),
}

class Stepper extends React.Component {
  StepsNode = () => {
    const { muiTheme } = this.context
    const {
      children,
      click = () => {},
      locale,
      activeStep,
      minStep,
      ...params
    } = getStepperParams(this.props, this.context)
    const stepIndex = Math.max(activeStep, minStep)
    const visited = range(0, stepIndex + 1)
    const stepsCount = children && children.length ? children.length : params.stepsCount
    const { error } = this.props
    return range(0, stepsCount).map((v, i) => (
      <Step key={i} index={i} style={styles.step} completed={visited.indexOf(i) !== -1} active={stepIndex === i}>
        <UILayout className={locale === 'ar' ? moduleStyles.rtlStep : ''}>
          <StyledStepButton
            iconContainerStyle={styles.iconContainerStyle}
            disabled={children && children[i].props.disabled}
            icon={
              <StepIcon
                theme={muiTheme.name}
                error={error}
                active={stepIndex === i}
                completed={visited.indexOf(i + 1) !== -1}
                hasNextStep={i < stepsCount - 1}
                strokeColor={this.props.strokeColor}
                label={
                  children && children[i]
                    ? React.cloneElement(children[i], { opacity: visited.indexOf(i) === -1 ? '0.5' : '1' })
                    : null
                }
                icon={children && children[i].props.icon}
              />
            }
            onClick={() => click(i, stepsCount)}
            style={{ overflow: 'visible' }}
          />
          {i < stepsCount - 1 ? (
            /* <UILayout position="relative" width="108px" style={{ transform: 'translate(-0.350em)' }}> */
            <UILayout position="relative" width="108px" style={styles.overflow} xs={xsLineStyles} {...muiTheme.ids.stepperLinesStyle}>
              <UILayout position="absolute" top="18px" width="auto">
                {visited.indexOf(i + 1) !== -1 ? (
                  <UIIcon type="pathOk" stroke={params.strokeColor} xs={xsLineStyles} />
                ) : (
                  <UIIcon type="pathDotted" stroke={params.strokeColor} xs={xsLineStyles} />
                )}
              </UILayout>
            </UILayout>
          ) : null}
        </UILayout>
      </Step>
    ))
  }

  render() {
    const { muiTheme } = this.context

    return (
      <UILayout margin="-20px 0 0 0" style={this.props.style} {...muiTheme.ids.stepperWrapperStyle}>
        <StepperMaterialUI connector="">
          <div className={moduleStyles.direction}>{this.StepsNode()}</div>
        </StepperMaterialUI>
      </UILayout>
    )
  }
}

Stepper.contextTypes = {
  muiTheme: PropTypes.object,
}

Stepper.propTypes = {
  strokeColor: PropTypes.string,
  error: PropTypes.any,
  style: PropTypes.any,
}

Stepper.defaultProps = {
  error: false,
}

export default Stepper
