export const THEME = {
  SAAREMAA: 'saaremaa',
  KIHNU: 'kihnu',
  GAVDOS: 'gavdos',
  POLFERRIES: 'polferries',
  ADMARITIME: 'admaritime',
  LIINILAEVAD: 'liinilaevad',
  BLUEWAVE: 'bluewave',
  DEMO: 'demo',
}
