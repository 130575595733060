import React from 'react'
import PropTypes from 'prop-types'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Input from '@material-ui/core/Input'
import { withStyles } from '@material-ui/core/styles'
import { equals, isEmpty } from 'ramda'
import { UIText, UIIcon, UILayout } from '../UI'
import { isLiinilaevad } from 'bora/src/utils/liinilaevadThemeUtils'
import { getMaritimeStyle, isMaritime } from 'bora/src/utils/maritimeStyleUtils'

const styles = {
  underline: {
    '&:after': { display: 'none' },
    '&:before': { display: 'none' },
  },
  inputRoot: {
    width: '100%',
    minHeight: '44px',
    size: '18px',
    color: '#000000',
    background: 'white',
    boxShadow: '0 11px 6px 0 rgba(0, 0, 0, 0.04), 0 6px 4px 0 rgba(0, 0, 0, 0.02)',
  },
  select: {
    padding: '0',
    paddingLeft: '10px',
    minHeight: '100%',
    '&:focus': {
      background: 'transparent',
    },
  },
}

const DropDownMenuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
}

const defaultItemTemplate = (props) => <UIText color="#000000" align="left" text={props.value.toString()} />

const DropDownIcon = ({ isOpened, locale }) => {
  const iconPosition = locale === 'ar' ? { left: '8px' } : { right: getMaritimeStyle('8px', '17px') }

  return (
    <UILayout position="absolute" top="37%" {...iconPosition} width="auto" pointerEvents="none">
      {
        isLiinilaevad && isOpened
          ? <UIIcon type={window.brandProps.muiTheme.iconChevronUp || 'iconChevronDown'} />
          : isMaritime 
          ? <UIIcon width="14px" type={window.brandProps.muiTheme.iconChevronDown || 'iconChevronDown'} /> 
          : <UIIcon type={window.brandProps.muiTheme.iconChevronDown || 'iconChevronDown'} />
      }
    </UILayout>
  )
}

class DropDownMenu extends React.Component {
  constructor(props, context) {
    super(props, context)
    const { fullItemsList = [], itemsArr, initValue } = props
    const items = isEmpty(fullItemsList) ? itemsArr : fullItemsList
    this.state = { value: items[initValue], opened: false }
    this.handleChange = this.handleChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  componentWillUpdate(nextProps) {
    const { initValue } = this.props
    if (!equals(nextProps.initValue, initValue)) {
      const { fullItemsList = [], itemsArr } = this.props
      const items = isEmpty(fullItemsList) ? itemsArr : fullItemsList
      this.setState({ value: items[nextProps.initValue] })
    }
  }

  handleChange = (event) => {
    const { itemsArr, select } = this.props
    const { value: index } = event.target
    const value = itemsArr[index]
    if (value || value === 0) {
      select(value, index)
    }
    this.setState({ value, opened: false })
  }

  handleClick = () => {
    this.setState({ opened: !this.state.opened })
  }

  handleBlur = () => {
    this.setState({ opened: false })
  }

  render() {
    const { id, itemsArr, classes, selectionRenderer, shevronRenderer, locale, ...params } = this.props
    const { muiTheme } = this.context

    const ItemTemplate = params.itemTemplate

    const items = itemsArr.map((value, key) => {
      const itemProps = {
        value,
        key,
        style: this.state.value === key ? params.menuItemPickedStyle : params.menuItemStyle,
      }

      return (
        <MenuItem {...itemProps} value={itemProps.key} style={{ ...muiTheme.ids.DropdownTicketSelect }}>
          <ItemTemplate {...itemProps} />
        </MenuItem>
      )
    })

    const MenuProps = {
      ...DropDownMenuProps,
      MenuListProps: params.MenuListProps,
    }
    const selectClasses = { select: classes.select }
    const inputClasses = {
      underline: classes.underline,
      root: classes.inputRoot,
    }

    return (
      <Select
        key={id}
        displayEmpty
        autoWidth={this.props.autoWidth}
        IconComponent={() => shevronRenderer(this.state.opened, locale)}
        onChange={this.handleChange}
        onClick={this.handleClick}
        onBlur={this.handleBlur}
        renderValue={selectionRenderer}
        value={params.onlySelect ? null : this.state.value}
        classes={selectClasses}
        input={<Input style={params.inputStyles} classes={inputClasses} />}
        MenuProps={MenuProps}
      >
        {items}
      </Select>
    )
  }
}

DropDownMenu.contextTypes = {
  muiTheme: PropTypes.object,
}

DropDownMenu.propTypes = {
  select: PropTypes.func,
  selectionRenderer: PropTypes.func,
  itemTemplate: PropTypes.func,
  itemsArr: PropTypes.array.isRequired,
  classes: PropTypes.any.isRequired,
  shevronRenderer: PropTypes.func,
  autoWidth: PropTypes.bool,
  id: PropTypes.any,
}

DropDownMenu.defaultProps = {
  shevronRenderer: (isOpened, locale) => <DropDownIcon isOpened={isOpened} locale={locale} />,
  autoWidth: false,
  select: () => {},
  selectionRenderer: (value = '') => value.toString(),
  itemTemplate: defaultItemTemplate,
}

export default withStyles(styles)(DropDownMenu)
