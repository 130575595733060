/* eslint-disable react/style-prop-object */
import React from 'react'
import PropTypes from 'prop-types'

import { ExtraRow } from '../components'
import { UIIcon, UILayout, UIFormattedNumber, UIText } from '../../../UI'

const carsCategories = {
  bike: 'iconBike',
  lightWeight: 'iconLightWeight',
  microbus: 'iconMicrobus',
  motorcycle: 'iconMotorcycle',
  trailer: 'iconTrailer',
  bigTruckTrailer: 'iconBigTruckTrailer',
  heavyWeightTrailer: 'iconHeavyWeightTrailer',
}

const Auto = (props) => (
  <UILayout row width="100%" padding="0 30px 0 0" xs={{ padding: '0px' }}>
    <UILayout center height="100%" minHeight="50px" width="70px" xs={{ height: '98px', maxWidth: '45px' }}>
      <UIIcon type={carsCategories[props.autoType]} width="100%" xs={{ height: '100%' }} />
    </UILayout>
    <UILayout column j-flex-space-between margin="0px 0px 0px 28px">
      <UIText
        size="16px"
        color="#000000"
        weight="bold"
        align="left"
      >
        {props.title},&nbsp;
        <UIFormattedNumber
          style="currency"
          value={props.price}
          minimumFractionDigits={0}
          currency={props.currency.code}
        />
      </UIText>
      <UILayout row center xs={{ column: true, row: false }} width="90%" padding="10px 0px 0px 0px">
        <UILayout row center>
          <UIIcon type="iconCarsHeight" width="7px" xs={{ width: '11px' }} />
          <UIText size="18px" color="#000000" weight="bold" align="left" margin="0px 0px 0px 10px">{props.height}</UIText>
        </UILayout>
        <UILayout row center>
          <UIIcon type="iconCarsLength" width="11px" />
          <UIText size="18px" color="#000000" weight="bold" align="left" margin="0px 0px 0px 10px">{props.length}</UIText>
        </UILayout>
        <UILayout row center>
          <UIIcon type="iconCarsWeight" width="6px" xs={{ width: '11px' }} />
          <UIText size="18px" color="#000000" weight="bold" align="left" margin="0px 0px 0px 10px">{props.weight}</UIText>
        </UILayout>
      </UILayout>
    </UILayout>
  </UILayout>
)

Auto.propTypes = {
  autoType: PropTypes.any.isRequired,
  price: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  length: PropTypes.string.isRequired,
  weight: PropTypes.string.isRequired
}

export default Auto
